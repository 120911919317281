import styled from 'styled-components';

import { Box } from '@mui/material';

export const FooterContainer = styled.footer`
  background: linear-gradient(
    -45deg,
    ${({ theme }) => theme.palette.primary.dark} -10%,
    ${({ theme }) => theme.palette.primary.main} 110%
  );
  color: ${({ theme }) => theme.palette.primary.contrastText};

  width: 100%;
  max-height: 40vh;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Content = styled(Box).attrs({
  display: 'grid',
})`
  width: 100%;
  box-sizing: border-box;
  padding: ${({ theme }) => theme.spacing(8)} ${({ theme }) => theme.spacing(12)} 0px
    ${({ theme }) => theme.spacing(12)};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: ${({ theme }) => theme.spacing(4)} ${({ theme }) => theme.spacing(2)} 0px
      ${({ theme }) => theme.spacing(2)};
  }
`;

export const FooterCopyright = styled(Box)`
  padding: 0 ${({ theme }) => theme.spacing(2)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: ${({ theme }) => theme.spacing(0.125)} solid;
  border-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    ${({ theme }) => theme.palette.grey[300]} 100%
  );
  border-image-slice: 1;
  width: 100%;
  padding: ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(12)};
  box-sizing: border-box;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: ${({ theme }) => theme.spacing(3)} ${({ theme }) => theme.spacing(2)};
    justify-content: center;
  }
`;
