import { useEffect, useMemo, useState } from 'react';
import Carousel from 'react-multi-carousel';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { ArrowForward } from '@mui/icons-material';
import { Box, Button, CircularProgress, Tab, Tabs, Typography } from '@mui/material';
import { Stack } from '@mui/system';

import { Policy } from './components/policy';
import { usePolicySuggestions } from './hooks/use-policy-suggestions';
import PhotoLeft from './images/photo-left.png';
import PhotoRight from './images/photo-right.png';
import { FilterPill } from '../../../components/filter-pill';
import { LogoColored } from '../../../components/icons/logo-colored';
import { HomeInsuranceForm, InsuredOption } from '../../../types/home-insurance';
import { useBuildingData } from '../preview-policy/hooks/use-building-data';
import { BaseContainer, TitleText } from '../styled';

const Container = styled(BaseContainer)`
  background: transparent;
`;

const gridTemplateColumns = {
  recommended: '1fr 2fr 1fr',
  price: '4fr',
  all: 'repeat(4, 1fr)',
};

const AVAILABLE_COMPANIES = [
  { key: 'sava', name: 'Сава' },
  { key: 'eurolink', name: 'Еуролинк' },
  { key: 'triglav', name: 'Триглав' },
  { key: 'croatia', name: 'Кроација' },
  { key: 'makedonija', name: 'Осигурување Македонија' },
  { key: 'winner', name: 'Винер' },
  { key: 'halk_insurance', name: 'Халк Осигурување' },
  { key: 'euroins', name: 'Евроинс' },
  { key: 'uniqa', name: 'Уника' },
];

const baseWordpressUrl = process.env.REACT_APP_WORDPRESS_BASE_URL;

const BuildingTypeLabelMap: Record<HomeInsuranceForm['buildingType'], string> = {
  apartment: 'стан',
  house: 'куќа',
};

const ConstructionTypeLabelMap: Record<HomeInsuranceForm['constructionType'], string> = {
  solid: 'цврста',
  mixed: 'мешовита',
};

const ItemsTypeLabelMap: Record<HomeInsuranceForm['itemsType'], string> = {
  lux: 'Луксузно',
  standard: 'Стандардно',
};

const InsuredOptionLabelMap: Record<InsuredOption, string> = {
  building: 'градежен дел',
  content: 'ствари во станот',
  neighbour: 'штети кон соседи',
  housing: 'нужно сместување',
};

export const Results = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const companies = searchParams.get('companies');
  const sorting = searchParams.get('sorting');

  const [selectedSorting, setSelectedSorting] = useState<'recommended' | 'price' | 'all'>(
    sorting ? (sorting as 'recommended' | 'price' | 'all') : 'recommended',
  );

  const [selectedFilters, setSelectedFilters] = useState<string[]>(
    companies ? companies.split(',') : AVAILABLE_COMPANIES.map(({ key }) => key),
  );

  useEffect(() => {
    setSearchParams((params) => {
      params.set('companies', selectedFilters.join(','));
      return params;
    });
  }, [selectedFilters, setSearchParams]);

  useEffect(() => {
    setSearchParams((params) => {
      params.set('sorting', selectedSorting);
      return params;
    });
  }, [selectedSorting, setSearchParams]);

  const handleSortingOrderSelect = (value: 'recommended' | 'price' | 'all') => {
    setSelectedSorting(value);

    if (value === 'all') {
      setSelectedFilters([]);
    }

    if (
      ['recommended', 'price'].includes(value) &&
      !['recommended', 'price'].includes(selectedSorting)
    ) {
      setSelectedFilters(AVAILABLE_COMPANIES.map(({ key }) => key));
    }
  };

  const buildingData = useBuildingData();

  const { data, isLoading, cheapestPolicy } = usePolicySuggestions(
    buildingData?.area ?? 0,
    selectedSorting,
    selectedFilters,
    buildingData?.itemsType,
  );

  const onReadMoreCalculationClick = () => {
    window.open(`${baseWordpressUrl}kako-sporeduva-kompa/`, '_blank');
  };

  const navigate = useNavigate();

  const pageSubtitle = useMemo(() => {
    if (!buildingData) {
      return '';
    }
    const buildingType = BuildingTypeLabelMap[buildingData.buildingType];

    const constructionType = ConstructionTypeLabelMap[buildingData.constructionType];

    const itemsType = ItemsTypeLabelMap[buildingData.itemsType];

    const furnishedVerb = buildingData.buildingType === 'apartment' ? 'опремен' : 'опремена';

    const insuredOptions = buildingData.insuredItems
      .filter((item) => item !== 'building')
      .map((item) => InsuredOptionLabelMap[item])
      .reduce((acc, curr, index, arr) => {
        if (index === arr.length - 1) {
          return `${acc} и ${curr}`;
        }

        return `${acc}, ${curr}`;
      });

    return `${itemsType} ${furnishedVerb} ${buildingType} од ${buildingData.area} метри квадратни во ${buildingData.city} со ${constructionType} конструкција и дополнително осигурување на ${insuredOptions}.`;
  }, [buildingData]);

  if (!buildingData) {
    return <Navigate to="../" />;
  }

  const startOver = () => navigate('../');

  if (isLoading) {
    return (
      <Container>
        <LogoColored />
        <CircularProgress />
      </Container>
    );
  }

  const onFilterClick = (key: string) => (active: boolean) => {
    if (active) {
      setSelectedFilters((prevFilters) => [...prevFilters, key]);
      return;
    }

    setSelectedFilters((prevFilters) => prevFilters.filter((filter) => filter !== key));
  };

  const recommendedPolicy = selectedSorting === 'recommended' ? data?.[0] : undefined;

  const carouselPolicies =
    selectedSorting === 'recommended'
      ? data?.slice(1).filter((policy) => policy.id !== cheapestPolicy?.id)
      : data;

  return (
    <Container>
      <LogoColored />
      <TitleText variant="h4" color="primary" fontWeight="bold" align="center">
        Го избравме најдоброто домаќинско за тебе, заедно!
      </TitleText>
      <Typography variant="body1" color="primary.dark" marginTop={3}>
        {pageSubtitle}
      </Typography>
      <Box marginTop={4} display="flex" alignItems="center">
        <Tabs value={selectedSorting} onChange={(e, v) => handleSortingOrderSelect(v)}>
          <Tab value="recommended" label="Најдоброто според Компа" />
          <Tab value="price" label="Најевтино прво" />
          <Tab value="all" label="Прикажи ги сите" />
        </Tabs>
      </Box>
      <Box marginTop={8} display="flex" alignItems="center">
        {AVAILABLE_COMPANIES.map(({ key, name }) => (
          <FilterPill
            onClick={onFilterClick(key)}
            label={name}
            active={selectedFilters.includes(key)}
            sx={{
              marginRight: (theme) => theme.spacing(1),
            }}
          />
        ))}
      </Box>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: gridTemplateColumns[selectedSorting],
          maxWidth: '1440px',
          gridGap: (theme) => theme.spacing(2),
          marginTop: (theme) => theme.spacing(6),
          width: '100%',
        }}
      >
        {selectedSorting === 'all'
          ? data?.map((policy) => <Policy policy={policy} recommended={policy.recommended} />)
          : null}
        {selectedSorting !== 'all' ? (
          <>
            {recommendedPolicy ? (
              <Policy policy={recommendedPolicy} recommended={recommendedPolicy.recommended} />
            ) : null}
            <Carousel
              swipeable={false}
              responsive={{
                desktop: {
                  breakpoint: { max: 4000, min: 1280 },
                  items: recommendedPolicy ? 2 : 4,
                },
                tablet: {
                  breakpoint: { max: 1279, min: 640 },
                  items: recommendedPolicy ? 1 : 3,
                },
                mobile: {
                  breakpoint: { max: 640, min: 0 },
                  items: 1,
                },
              }}
            >
              {carouselPolicies?.map((policy) => <Policy key={policy.id} policy={policy} />)}
            </Carousel>
            {cheapestPolicy ? <Policy policy={cheapestPolicy} cheapest /> : null}
          </>
        ) : null}
      </Box>
      <Box width="100%" maxWidth="1440px">
        <Button variant="text" color="info" onClick={startOver}>
          Почни од почеток
        </Button>
      </Box>
      <Stack
        maxWidth="1440px"
        direction="row"
        paddingY={0}
        paddingX={8}
        marginY={4}
        boxSizing="border-box"
        sx={{
          borderRadius: 2,
          backgroundColor: 'primary.light',
        }}
      >
        <Stack justifyContent="flex-end">
          <img src={PhotoLeft} alt="woman speaking on phone" />
        </Stack>
        <Stack justifyContent="center" alignItems="center" paddingY={4} marginX={6} paddingX={4}>
          <Typography variant="h1" color="primary.dark" align="center">
            Како споредуваме?
          </Typography>
          <Typography
            variant="h5"
            letterSpacing="0.04em"
            color="primary"
            align="center"
            marginY={4}
          >
            Компа користи напреден алгоритам направен од нашите експерти кој секогаш ја дава
            најдобрата понуда за вас
          </Typography>
          <Button
            variant="contained"
            color="accent"
            size="large"
            onClick={onReadMoreCalculationClick}
            endIcon={
              <ArrowForward
                sx={{
                  color: 'primary.dark',
                }}
              />
            }
          >
            Прочитај повеќе
          </Button>
        </Stack>
        <Stack justifyContent="flex-start">
          <img src={PhotoRight} alt="2 men working on laptop" />
        </Stack>
      </Stack>
    </Container>
  );
};
