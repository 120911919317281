export const LogoColored = () => (
  <svg width="217" height="74" viewBox="0 0 217 74" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_70_38342)" filter="url(#filter0_d_70_38342)">
      <path
        d="M78.7946 35.9457C77.4462 38.3094 75.5977 40.1415 73.2588 41.4419C70.9168 42.7455 68.3392 43.3957 65.5294 43.3957C62.7196 43.3957 60.0936 42.7455 57.7709 41.4419C55.4482 40.1383 53.6094 38.3062 52.261 35.9457C50.9125 33.5819 50.2383 30.9139 50.2383 27.9352C50.2383 24.9564 50.9125 22.2884 52.261 19.9278C53.6094 17.5641 55.445 15.7224 57.7709 14.4028C60.0936 13.0832 62.6809 12.4202 65.5294 12.4202C68.3779 12.4202 70.9168 13.08 73.2588 14.4028C75.6009 15.7224 77.4462 17.5673 78.7946 19.9278C80.1431 22.2916 80.8173 24.9596 80.8173 27.9352C80.8173 30.9107 80.1431 33.5819 78.7946 35.9457ZM72.9201 23.0539C72.1523 21.6222 71.12 20.514 69.8296 19.7325C68.5392 18.9509 67.1037 18.5602 65.5294 18.5602C63.9551 18.5602 62.5228 18.9509 61.2292 19.7325C59.9356 20.514 58.9065 21.6222 58.1387 23.0539C57.3709 24.4856 56.987 26.1159 56.987 27.9384C56.987 29.7608 57.3709 31.3815 58.1387 32.794C58.9065 34.2097 59.9388 35.3051 61.2292 36.0866C62.5228 36.8681 63.9551 37.2589 65.5294 37.2589C67.1037 37.2589 68.536 36.8681 69.8296 36.0866C71.1232 35.3051 72.1523 34.2097 72.9201 32.794C73.6879 31.3815 74.0718 29.7608 74.0718 27.9384C74.0718 26.1159 73.6879 24.4888 72.9201 23.0539Z"
        fill="#1A7CAF"
      />
      <path
        d="M160.868 14.4028C162.965 15.7224 164.626 17.5577 165.842 19.899C167.058 22.2435 167.668 24.9052 167.668 27.8807C167.668 30.8562 167.068 33.5275 165.868 35.8912C164.668 38.255 163.029 40.0967 160.948 41.4163C158.868 42.7359 156.574 43.3989 154.061 43.3989C152.261 43.3989 150.567 43.037 148.974 42.3099C147.38 41.5828 146.061 40.702 145.009 39.6579V50.5959C145.009 51.5632 144.69 52.3735 144.054 53.0237C143.415 53.6739 142.612 54.0006 141.638 54.0006C140.664 54.0006 139.857 53.6835 139.221 53.0525C138.583 52.4183 138.267 51.6016 138.267 50.5959V16.3822C138.267 15.4149 138.586 14.6046 139.221 13.9544C139.857 13.3042 140.664 12.9775 141.638 12.9775C142.612 12.9775 143.418 13.3042 144.054 13.9544C144.69 14.6046 145.009 15.4149 145.009 16.3822V16.6064C145.909 15.4886 147.164 14.5117 148.774 13.6757C150.383 12.8397 152.071 12.4202 153.832 12.4202C156.419 12.4202 158.758 13.08 160.858 14.4028H160.868ZM158.9 34.578C160.436 32.7908 161.203 30.5583 161.203 27.8807C161.203 25.2031 160.445 22.9802 158.926 21.2122C157.406 19.4442 155.432 18.5602 152.996 18.5602C150.561 18.5602 148.574 19.4442 147.038 21.2122C145.502 22.9802 144.735 25.2031 144.735 27.8807C144.735 30.5583 145.502 32.7908 147.038 34.578C148.574 36.3653 150.561 37.2557 152.996 37.2557C155.432 37.2557 157.361 36.3621 158.9 34.578Z"
        fill="#1A7CAF"
      />
      <path
        d="M200.046 13.3682C200.681 14.0024 201.001 14.8192 201.001 15.8249V39.4337C201.001 40.401 200.681 41.2113 200.046 41.8615C199.407 42.5117 198.604 42.8384 197.63 42.8384C196.655 42.8384 195.859 42.5213 195.239 41.8903C194.62 41.2593 194.294 40.4586 194.255 39.4913C193.317 40.5707 192.052 41.4931 190.462 42.2522C188.868 43.0145 187.19 43.3957 185.429 43.3957C182.842 43.3957 180.5 42.7359 178.403 41.4131C176.303 40.0935 174.645 38.2582 173.428 35.9169C172.209 33.5723 171.603 30.9139 171.603 27.9352C171.603 24.9564 172.203 22.2884 173.403 19.9278C174.603 17.5641 176.241 15.7224 178.322 14.4028C180.403 13.0832 182.697 12.4202 185.21 12.4202C187.01 12.4202 188.694 12.7469 190.268 13.3971C191.842 14.0473 193.171 14.8768 194.259 15.8793V15.8249C194.259 14.8576 194.578 14.0473 195.213 13.3971C195.849 12.7469 196.655 12.4202 197.63 12.4202C198.604 12.4202 199.41 12.7373 200.046 13.3682ZM192.233 34.6037C193.768 32.8356 194.536 30.6128 194.536 27.9352C194.536 25.2575 193.768 23.0251 192.233 21.2378C190.697 19.4506 188.71 18.5602 186.274 18.5602C183.839 18.5602 181.906 19.4538 180.371 21.2378C178.835 23.0251 178.067 25.2575 178.067 27.9352C178.067 30.6128 178.826 32.8388 180.345 34.6037C181.861 36.3717 183.839 37.2557 186.274 37.2557C188.71 37.2557 190.697 36.3717 192.233 34.6037Z"
        fill="#1A7CAF"
      />
      <path
        d="M106.307 41.4413C105.672 40.7911 105.353 39.9808 105.353 39.0135V24.8373C105.353 20.8177 106.469 17.6756 108.698 15.4047C110.927 13.1338 114.105 12 118.227 12C122.35 12 125.518 13.1338 127.728 15.4047C129.941 17.6724 131.044 20.8177 131.044 24.8373V39.0135C131.044 39.9808 130.725 40.7911 130.089 41.4413C129.454 42.0915 128.647 42.4182 127.673 42.4182C126.699 42.4182 125.892 42.0915 125.257 41.4413C124.621 40.7911 124.302 39.9808 124.302 39.0135V24.8373C124.302 22.5665 123.795 20.8849 122.786 19.7863C121.773 18.6877 120.257 18.14 118.234 18.14C116.211 18.14 114.637 18.6877 113.624 19.7863C112.611 20.8817 112.108 22.5665 112.108 24.8373V39.0135C112.108 39.9808 111.788 40.7911 111.153 41.4413C110.517 42.0915 109.711 42.4182 108.737 42.4182C107.762 42.4182 106.956 42.0915 106.32 41.4413H106.307Z"
        fill="#1A7CAF"
      />
      <path
        d="M87.3543 41.4413C86.7188 40.7911 86.3994 39.9808 86.3994 39.0135V24.8373C86.3994 20.8177 87.5156 17.6756 89.7447 15.4047C91.9739 13.1338 95.1515 12 99.2743 12C103.397 12 106.565 13.1338 108.775 15.4047C110.988 17.6724 112.091 20.8177 112.091 24.8373V39.0135C112.091 39.9808 111.772 40.7911 111.136 41.4413C110.501 42.0915 109.694 42.4182 108.72 42.4182C107.746 42.4182 106.939 42.0915 106.304 41.4413C105.668 40.7911 105.349 39.9808 105.349 39.0135V24.8373C105.349 22.5665 104.842 20.8849 103.833 19.7863C102.82 18.6877 101.303 18.14 99.2807 18.14C97.258 18.14 95.6838 18.6877 94.6708 19.7863C93.6579 20.8817 93.1546 22.5665 93.1546 24.8373V39.0135C93.1546 39.9808 92.8352 40.7911 92.1997 41.4413C91.5642 42.0915 90.7577 42.4182 89.7835 42.4182C88.8092 42.4182 88.0027 42.0915 87.3672 41.4413H87.3543Z"
        fill="#1A7CAF"
      />
      <path
        d="M41.7688 41.4259C40.8397 41.4259 39.9654 41.0672 39.3073 40.4138L33.6006 34.7478L38.5234 29.8601L44.2302 35.5261C44.8883 36.1763 45.2496 37.0443 45.2496 37.9699C45.2496 38.8956 44.8883 39.7636 44.2302 40.4138C43.5721 41.0639 42.6978 41.4259 41.7688 41.4259Z"
        fill="#1A7CAF"
      />
      <path
        d="M17.2284 18.4953C16.5703 17.8419 16.209 16.9739 16.209 16.0515C16.209 15.129 16.5703 14.261 17.2284 13.6076C17.6639 13.1752 18.2026 12.8678 18.7865 12.7108C19.0124 12.666 19.2446 12.6436 19.4801 12.6436H19.5608C21.4028 12.6884 22.9577 14.2995 22.9577 16.1636V24.1869L17.2284 18.4953Z"
        fill="#F26741"
      />
      <path
        d="M19.6937 42.2353C19.2615 42.2353 18.8421 42.1584 18.4421 42.0079C18.4292 42.0015 18.413 41.9983 18.4001 41.9919C16.9646 41.5243 16 40.2047 16 38.7089V22.1626L23.0617 29.1738C23.2488 29.3563 23.4939 29.4588 23.7552 29.4588C24.0165 29.4588 24.2617 29.3563 24.4488 29.1738L39.3077 14.4179C39.9658 13.7645 40.84 13.4058 41.7691 13.4058C42.6982 13.4058 43.5724 13.7645 44.2305 14.4179C44.8886 15.0713 45.2499 15.9393 45.2499 16.8617C45.2499 17.7842 44.8886 18.6554 44.2305 19.3056L22.1552 41.2232C21.4971 41.8766 20.626 42.2353 19.6937 42.2353Z"
        fill="#47C2CB"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_70_38342"
        x="0"
        y="0"
        width="217"
        height="74"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="8" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0627451 0 0 0 0 0.0627451 0 0 0 0 0.0627451 0 0 0 0.08 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_70_38342" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_70_38342"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_70_38342">
        <rect width="185" height="42" fill="white" transform="translate(16 12)" />
      </clipPath>
    </defs>
  </svg>
);
