import { useState } from 'react';

import { Send } from '@mui/icons-material';
import { Button, TextField } from '@mui/material';

interface Props {
  onSubmit: (value: string) => Promise<void> | void;
  placeholder?: string;
}

/**
 * An self-contained input with a submit button.
 */
export const ContainedInput = ({ onSubmit, placeholder }: Props) => {
  const [value, setValue] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async () => {
    setSubmitting(true);
    await onSubmit(value);
    setSubmitting(false);
    setValue('');
  };

  return (
    <TextField
      value={value}
      onChange={(e) => setValue(e.target.value)}
      variant="filled"
      fullWidth
      placeholder={placeholder}
      InputProps={{
        endAdornment: (
          <Button variant="contained" onClick={handleSubmit} disabled={submitting} color="accent">
            <Send />
          </Button>
        ),
        disableUnderline: true,
        sx: (theme) => ({
          fontSize: theme.typography.subtitle2.fontSize,
        }),
      }}
    />
  );
};
