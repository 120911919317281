import { SvgIconComponent } from '@mui/icons-material';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Avalanche: SvgIconComponent = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 22 22">
    <path d="M13.97 13.79L11.39 12.76L4.5 15.05L0.5 13.5V15.61L4.5 16.95L13.97 13.79ZM9.07 11.42L6.5 8H0.5V11.61L4.5 12.95L9.07 11.42ZM4.5 19.05L0.5 17.72V22H20.5L15.53 15.38L4.5 19.05ZM15.5 6V1L10.5 0L7.5 2V6L10.5 8L15.5 6ZM17 7L14.5 9V12L17 14L21.5 12V8L17 7Z" />
  </SvgIcon>
);

Avalanche.muiName = 'Avalanche';
