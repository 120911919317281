import { Box, Typography } from '@mui/material';

import { Review as ReviewType } from '../../types';

interface Props {
  review: ReviewType;
}

export const Review = ({ review }: Props) => (
  <Box display="flex" flexDirection="column" alignItems="center">
    <Typography variant="body1" fontWeight={600} color="primary.dark" align="center">
      „{review.comment}“
    </Typography>
    <Typography
      marginBottom={3}
      variant="body1"
      sx={{
        fontStyle: 'italic',
        marginTop: (theme) => theme.spacing(2),
      }}
      color="primary.dark"
      fontWeight={300}
      align="center"
    >
      {review.reviewer}
    </Typography>
  </Box>
);
