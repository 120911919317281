import { useNavigate } from 'react-router';
import styled from 'styled-components';

import { Button, lighten } from '@mui/material';

import { InsuranceType } from '../../../types/insurance-type';

const SelectorContainer = styled.div`
  background-color: ${({ theme }) => lighten(theme.palette.primary.light, 0.5)};
  color: ${({ theme }) => theme.palette.primary.dark};
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing(4)};
  width: 80%;
  border-radius: ${({ theme }) => theme.spacing(1)};
  box-sizing: border-box;
  margin-top: ${({ theme }) => theme.spacing(6)};
  min-height: ${({ theme }) => theme.spacing(50)};
  align-items: center;
  justify-content: space-between;
`;

const InsuranceRoutes = {
  [InsuranceType.HOME]: 'home',
  [InsuranceType.HEALTH]: 'health',
};

export const InsuranceTypeSelector = () => {
  const navigate = useNavigate();

  const onInsuranceSelected = (insuranceType: InsuranceType) => () => {
    navigate(`/insurance/${InsuranceRoutes[insuranceType]}`);
  };

  return (
    <SelectorContainer>
      <Button
        variant="text"
        color="inherit"
        size="large"
        onClick={onInsuranceSelected(InsuranceType.HOME)}
      >
        Домаќинско осигурување
      </Button>
      <Button
        variant="text"
        color="inherit"
        size="large"
        onClick={onInsuranceSelected(InsuranceType.HEALTH)}
      >
        Здравствено осигурување
      </Button>
    </SelectorContainer>
  );
};
