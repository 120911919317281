import { Navigate, useParams } from 'react-router';

import { Box, CircularProgress } from '@mui/material';

import { BasicInfo } from './components/basic-info';
import { KompaAdvantages } from './components/kompa-advantages';
import { PurchaseForm } from './components/purchase-form';
import { usePolicyData } from './hooks/use-policy-data';
import { LogoColored } from '../../../components/icons/logo-colored';
import { TitleText } from '../styled';

export const PreviewPolicy = () => {
  const { policyId: policyIdParam } = useParams<{ policyId: string }>();

  const policyId = Number.parseInt(policyIdParam ?? '', 10);

  const { policy, isLoading } = usePolicyData(policyId);

  if (!policyId || Number.isNaN(policyId)) {
    return <Navigate to="../../" />;
  }

  if (!policy || isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box display="flex" alignItems="center" flexDirection="column" paddingTop={3}>
      <Box display="flex" justifyContent="center">
        <LogoColored />
      </Box>
      <Box display="flex" justifyContent="center">
        <TitleText variant="h4" color="primary" fontWeight="bold" align="center">
          Одбравте понуда за домаќинско осигурување од:
        </TitleText>
      </Box>
      <Box
        display="grid"
        gridTemplateColumns="3fr 1fr"
        maxWidth="1440px"
        marginTop={3}
        sx={{
          gridColumnGap: (theme) => theme.spacing(5),
        }}
      >
        <BasicInfo policy={policy} />
        <KompaAdvantages />
        <PurchaseForm policy={policy} />
      </Box>
    </Box>
  );
};
