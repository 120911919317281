import styled from 'styled-components';

import { MainColor } from '../../styles/types';

export const IconFilledContainer = styled.div<{
  active?: boolean;
  color?: MainColor;
  width?: string;
}>`
  width: ${({ theme, width }) => width ?? theme.spacing(6)};
  height: ${({ theme, width }) => width ?? theme.spacing(6)};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 50%;
  background: ${({ theme, active, color }) =>
    active ? theme.palette[color ?? 'accent'].main : theme.palette.grey[400]};
  position: relative;
`;

export const TooltipIcon = styled(IconFilledContainer)`
  background: ${({ theme }) => theme.palette.grey[300]};
  width: ${({ theme }) => theme.spacing(1.25)};
  height: ${({ theme }) => theme.spacing(1.25)};
  position: absolute;
  top: -3px;
  right: -3px;
`;
