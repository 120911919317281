import { Navigate } from 'react-router';

import { ArrowOutward } from '@mui/icons-material';
import { Box, Button, Divider, Stack, Typography } from '@mui/material';

import { BasicInfoContainer, Logo, PriceContainer } from './styled';
import { BuildingType } from '../../../../../types/home-insurance';
import { useNavigatePreserveQuery } from '../../../../../util/useNavigatePreserveQuery';
import { CompanyLogoMap } from '../../../results/components/policy/constants';
import { PolicySuggestion } from '../../../results/types';
import { useBuildingData } from '../../hooks/use-building-data';

interface Props {
  policy: PolicySuggestion;
}

const BuildingTypeLabel: Record<BuildingType, string> = {
  apartment: 'Стан',
  house: 'Куќа',
};

export const BasicInfo = ({ policy }: Props) => {
  const logoSource = CompanyLogoMap[policy.company];

  const buildingData = useBuildingData();

  const navigate = useNavigatePreserveQuery();

  if (!buildingData) {
    return <Navigate to="/" />;
  }

  const openPolicyDetails = () => {
    navigate('./details');
  };

  const { city, area, buildingType } = buildingData;

  return (
    <Box display="flex" width="100%" flexDirection="column">
      <BasicInfoContainer>
        <Box padding={4}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Logo src={logoSource} />
          </Box>
          <Typography variant="h6" fontWeight={400} color="primary.dark" marginTop={3}>
            {policy.name}
          </Typography>
          <Box marginTop={3} marginBottom={3}>
            <Divider />
          </Box>
          <Box display="flex" flexDirection="column">
            <Box display="flex">
              <Typography variant="body1" color="primary.dark" fontWeight={300}>
                Објект
              </Typography>
              <Typography variant="body1" color="primary.dark" fontWeight="bold" marginLeft={2}>
                {BuildingTypeLabel[buildingType]}
              </Typography>
            </Box>
            <Box marginTop={2} display="flex">
              <Typography variant="body1" color="primary.dark" fontWeight={300}>
                Општина
              </Typography>
              <Typography variant="body1" color="primary.dark" fontWeight="bold" marginLeft={2}>
                {city}
              </Typography>
            </Box>
            <Box marginTop={2} display="flex">
              <Typography variant="body1" color="primary.dark" fontWeight={300}>
                Квадратура
              </Typography>
              <Typography variant="body1" color="primary.dark" fontWeight="bold" marginLeft={2}>
                {area} м<sup>2</sup>
              </Typography>
            </Box>
          </Box>
          <Box marginTop={3} marginBottom={3}>
            <Divider />
          </Box>
          <Box display="flex" flexDirection="column">
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Typography variant="body1" color="primary.dark" fontWeight={300}>
                Подарок
              </Typography>
              <Typography
                variant="body1"
                color="primary.dark"
                fontWeight="bold"
                marginLeft={2}
                fontSize={18}
              >
                чадор
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Typography variant="body1" color="primary.dark" fontWeight={300}>
                Обработка од страна на Компа
              </Typography>
              <Typography
                variant="body1"
                color="primary.dark"
                fontWeight="bold"
                marginLeft={2}
                fontSize={18}
              >
                бесплатно
              </Typography>
            </Box>
          </Box>
        </Box>
        <PriceContainer display="flex" flexDirection="column">
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography
              variant="h6"
              fontWeight={400}
              color="primary.dark"
              textTransform="uppercase"
            >
              Вкупно
            </Typography>
            <Typography
              variant="body1"
              color="primary.dark"
              fontWeight="bold"
              marginLeft={2}
              fontSize={26}
            >
              {policy.totalPremium} ден
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="body1" color="grey.600" fontWeight={300}>
              со вклучен данок
            </Typography>
            <Typography variant="body1" color="grey.600" fontWeight={300}>
              годишно
            </Typography>
          </Box>
        </PriceContainer>
      </BasicInfoContainer>
      <Stack direction="row" width="100%" justifyContent="flex-end" marginTop={2}>
        <Button
          variant="contained"
          color="primary"
          endIcon={<ArrowOutward />}
          disableRipple
          onClick={openPolicyDetails}
          sx={{
            marginRight: 6,
          }}
        >
          Прочитај ги сите детали за полисата
        </Button>
      </Stack>
    </Box>
  );
};
