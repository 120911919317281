import { useState } from 'react';

import { PriorityHigh, SvgIconComponent } from '@mui/icons-material';
import { Box, Tooltip, Typography } from '@mui/material';

import { IconFilledContainer, TooltipIcon } from './styled';
import { MainColor } from '../../styles/types';

interface Props {
  icon?: SvgIconComponent;
  active?: boolean;
  tooltip?: string;
  color?: MainColor;
  width?: string;
  label?: string;
  iconSize?: string;
  description?: string;
}

export const IconFilled = ({
  icon: Icon,
  active,
  tooltip,
  color = 'accent',
  width = '48px',
  label,
  iconSize = 'large',
  description,
}: Props) => {
  const [isOverTooltipIcon, setIsOverTooltipIcon] = useState(false);

  const iconContent = (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
      <IconFilledContainer active={active} color={color} width={width}>
        {tooltip ? (
          <TooltipIcon
            onMouseEnter={() => setIsOverTooltipIcon(true)}
            onMouseLeave={() => setIsOverTooltipIcon(false)}
          >
            <PriorityHigh
              sx={{
                color: 'common.white',
                fontSize: '0.5rem',
              }}
            />
          </TooltipIcon>
        ) : null}
        {Icon ? (
          <Icon
            sx={(theme) => ({
              color: theme.palette.common.white,
              fontSize: iconSize,
            })}
          />
        ) : null}
      </IconFilledContainer>
      {label ? (
        <Typography variant="caption" color="grey.400" align="center">
          {label}
        </Typography>
      ) : null}
    </Box>
  );
  return (tooltip && tooltip !== '') || description !== '' ? (
    <Tooltip title={isOverTooltipIcon ? tooltip : description} placement="top" enterTouchDelay={0}>
      {iconContent}
    </Tooltip>
  ) : (
    iconContent
  );
};
