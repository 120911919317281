import styled from 'styled-components';

import { Box } from '@mui/material';

export const InsuranceDropdownContainer = styled(Box)`
  flex-grow: 1;
  margin-left: ${({ theme }) => theme.spacing(6)};
`;

export const MenuItemsContainer = styled(Box)`
  flex-grow: 0;
  margin-right: ${({ theme }) => theme.spacing(6)};
`;
