import { Box, Typography } from '@mui/material';

import { Content, FooterContainer, FooterCopyright } from './styled';
import { useIsTabletOrLarger } from '../../util/useIsTabletOrLarger';
import { ContainedInput } from '../contained-input';
import { LogoWhite } from '../icons/logo-white';

const LINKS = ['За нас', 'Кариера', 'Поддршка', 'Ресурси'];

export const Footer = () => {
  const isTabletOrLarger = useIsTabletOrLarger();

  return (
    <FooterContainer>
      <Content
        gridTemplateColumns={{
          xs: '1fr',
          md: '1.5fr 1fr 1fr',
        }}
      >
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          <Box
            marginBottom={{
              xs: 0.25,
              md: 2,
            }}
          >
            <LogoWhite />
          </Box>
          <Box
            width={{
              xs: '100%',
              md: 'inherit',
            }}
            display="flex"
            flexDirection={{
              xs: 'row',
              md: 'column',
            }}
            alignItems="flex-start"
            justifyContent={{
              xs: 'space-between',
            }}
          >
            <Typography variant="subtitle2" color="grey.400">
              02 234 5678
            </Typography>
            <Typography variant="subtitle2" color="grey.400">
              support@kompa.mk
            </Typography>
          </Box>
        </Box>
        <Box
          marginTop={{
            xs: 2,
            md: 0,
          }}
          display="flex"
          flexDirection={{
            xs: 'row',
            md: 'column',
          }}
          alignItems="flex-start"
          justifyContent={{
            xs: 'space-between',
          }}
        >
          {isTabletOrLarger ? (
            <Typography variant="subtitle2" color="common.white" fontWeight="bold" marginBottom={2}>
              Линкови
            </Typography>
          ) : null}
          {LINKS.map((link) => (
            <Typography key={link} variant="subtitle2" color="common.white" marginBottom={1}>
              {link}
            </Typography>
          ))}
        </Box>
        {isTabletOrLarger ? (
          <Box display="flex" flexDirection="column" alignItems="flex-start" width="100%">
            <Typography variant="subtitle2" color="common.white" fontWeight="bold" marginBottom={2}>
              Претплати се
            </Typography>
            <Typography variant="subtitle2" color="grey.400" marginBottom={1}>
              Само корисни вести, без спам
            </Typography>
            <ContainedInput onSubmit={() => {}} placeholder="Вашата имејл адреса" />
          </Box>
        ) : null}
      </Content>
      <FooterCopyright>
        <Typography variant="subtitle2" color="grey.200">
          © Компа 2023. Сите права се задржани.
        </Typography>
      </FooterCopyright>
    </FooterContainer>
  );
};
