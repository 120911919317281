import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material';

interface Props {
  onNext?: VoidFunction;
  onPrev?: VoidFunction;
  nextLabel?: string;
  prevLabel?: string;
  nextSubtitle?: string;
  prevSubtitle?: string;
}

export const FormNavigation = ({
  onNext,
  onPrev,
  nextLabel,
  prevLabel,
  prevSubtitle,
  nextSubtitle,
}: Props) => {
  const theme = useTheme();
  const isTabletOrLarger = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box
      display="flex"
      justifyContent={prevLabel ? 'space-between' : 'flex-end'}
      alignItems="flex-start"
      marginTop={2}
      paddingX={6}
    >
      {prevLabel ? (
        <Box display="flex" flexDirection="column" alignItems="center">
          <Button
            variant="contained"
            color="info"
            startIcon={isTabletOrLarger ? <ArrowBack sx={{ color: 'primary.dark' }} /> : null}
            onClick={onPrev}
            disabled={!onPrev}
            fullWidth={!isTabletOrLarger}
          >
            {prevLabel}
          </Button>
          <Typography variant="caption" color="grey.400" marginTop={1}>
            {prevSubtitle}
          </Typography>
        </Box>
      ) : null}
      {nextLabel ? (
        <Box display="flex" flexDirection="column" alignItems="center">
          <Button
            variant="contained"
            color="accent"
            endIcon={isTabletOrLarger ? <ArrowForward sx={{ color: 'primary.dark' }} /> : null}
            onClick={onNext}
            fullWidth={!isTabletOrLarger}
          >
            {nextLabel}
          </Button>
          <Typography variant="caption" color="grey.400" marginTop={1}>
            {nextSubtitle}
          </Typography>
        </Box>
      ) : null}
    </Box>
  );
};
