import { useBuildingData } from './use-building-data';
import { usePolicySuggestions } from '../../results/hooks/use-policy-suggestions';

export const usePolicyData = (policyId: number) => {
  const buildingData = useBuildingData();

  const { data, isLoading } = usePolicySuggestions(
    buildingData?.area ?? 0,
    'all',
    [],
    buildingData?.itemsType,
  );

  return {
    isLoading,
    policy: !buildingData ? undefined : data?.find((policy) => policy.id === policyId),
  };
};
