import { InsuredOption } from '../../types/home-insurance';

export type ApiCompanyKey =
  | 'sava'
  | 'triglav'
  | 'winner'
  | 'eurolink'
  | 'croatia'
  | 'makedonija'
  | 'halk_insurance'
  | 'uniqa'
  | 'euroins';

export interface ApiCompany {
  id: number;
  name: string;
  key: ApiCompanyKey;
}

export interface ApiRisk {
  id: number;
  name: string;
  key: string;
  descriptionTitle: string;
  descriptionText: string;
  rating: number | null;
  bsm: 'BITE' | 'SNACK' | 'MEAL';
  exception: string;
  exceptionRating: number | null;
  limit: string;
  limitRating: number | null;
  deductible: string;
  deductibleRating: number | null;
  gracePeriod: string;
  gracePeriodRating: number | null;
  insurancePackage: number;
  note?: string;
}

export type ApiSubjectType = 'CONSTRUCTION_PART' | 'ITEMS' | 'THIRD_PARTY_DAMAGE' | 'ACCOMMODATION';

export const fromApiSubjectType = (type: ApiSubjectType): InsuredOption => {
  switch (type) {
    case 'CONSTRUCTION_PART':
      return 'building';
    case 'ITEMS':
      return 'content';
    case 'THIRD_PARTY_DAMAGE':
      return 'neighbour';
    case 'ACCOMMODATION':
      return 'housing';
    default:
      return 'building';
  }
};

export interface ApiSubject {
  id: number;
  type: ApiSubjectType;
  valueSolidConstruction: number | null;
  valueMixedConstruction: number | null;
  exception: string;
  exceptionRating: number | null;
  limit: string;
  limitRating: number | null;
  deductible: string;
  deductibleRating: number | null;
  gracePeriod: string;
  gracePeriodRating: number | null;
  calculationTypeSolid: string | null;
  calculationTypeMixed: string | null;
  insurancePackage: number;
  totalSum: number;
  note?: string;
}

export interface ApiReview {
  id: number;
  grade: number;
  comment: string;
  reviewer: string;
  type: 'EASE_OF_ISSUING' | 'DAMAGE_REPORT';
}

export interface ApiPolicy {
  id: number;
  name: string;
  kompaRating: number;
  frameText?: string;
  company: ApiCompany;
  totalPremium: number;
  risks: Array<ApiRisk>;
  subjects: Array<ApiSubject>;
  pros: Array<string>;
  cons: Array<string>;
  reviews: Array<ApiReview>;
  kompaReview: string;
}
