import { styled } from 'styled-components';

import { Box, Typography } from '@mui/material';

import { ORANGE } from '../../styles/colors';

export const BaseContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({ theme }) => theme.palette.accent.light};
  width: 100%;
  padding-top: ${({ theme }) => theme.spacing(3)};
  padding-bottom: ${({ theme }) => theme.spacing(2)};
`;

export const TitleText = styled(Typography).attrs({
  variant: 'h6',
})`
  background: ${({ theme }) =>
    `-webkit-linear-gradient(0deg, ${theme.palette.primary.dark} 30%, ${ORANGE[200]} 70%)`};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const ChoicesContainer = styled(Box).attrs({
  display: 'grid',
})`
  grid-column-gap: ${({ theme }) => theme.spacing(3)};
  ${({ theme }) => theme.breakpoints.down('sm')} {
    grid-column-gap: ${({ theme }) => theme.spacing(2)};
  }
`;

export const MixedConstructionMessageContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing(3)};
`;
