import { useQuery } from '@tanstack/react-query';

import { fetchPolicySuggestions } from '../../../../api/policies';
import { ApiPolicy, fromApiSubjectType } from '../../../../api/policies/types';
import { ConstructionType } from '../../../../types/home-insurance';
import { useBuildingData } from '../../preview-policy/hooks/use-building-data';
import { fromApiPolicy } from '../types';

export const usePolicySuggestions = (
  size: number,
  sortOrder: 'recommended' | 'price' | 'all' = 'recommended',
  activeFilters: string[] = [],
  itemsType: 'standard' | 'lux' = 'standard',
  enabled = true,
  selectedInsuredItems: string[] = [],
) => {
  const buildingData = useBuildingData();
  const objectType = buildingData?.buildingType ?? 'apartment';

  const constructionType: ConstructionType = 'solid'; // no automated suggestions for mixed construction

  const { isLoading, data: apiPolicies } = useQuery({
    queryKey: ['policySuggestions', size, constructionType, objectType],
    queryFn: () => fetchPolicySuggestions(size, constructionType, itemsType, objectType),
    enabled,
  });

  const insuredItemsFromStore = buildingData?.insuredItems ?? [];
  const insuredItems =
    selectedInsuredItems.length > 0 ? selectedInsuredItems : insuredItemsFromStore;

  const sortFn = (a: ApiPolicy, b: ApiPolicy) =>
    sortOrder !== 'price' ? b.kompaRating - a.kompaRating : a.totalPremium - b.totalPremium;

  const filterFn = (policy: ApiPolicy) => {
    if (sortOrder !== 'all') {
      return activeFilters.includes(policy.company.key);
    }

    return activeFilters.length === 0 ? true : activeFilters.includes(policy.company.key);
  };

  const filteredPolicies =
    apiPolicies?.filter((offer) =>
      insuredItems.every((selectedItem) =>
        offer.subjects.some((subject) => fromApiSubjectType(subject.type) === selectedItem),
      ),
    ) ?? [];

  const sortedPolicies = [...(filteredPolicies?.filter(filterFn).sort(sortFn) ?? [])];

  // cheapest policy
  const cheapestPolicy =
    sortOrder === 'recommended'
      ? sortedPolicies?.reduce((acc, policy) => {
          if (acc.totalPremium > policy.totalPremium) {
            return policy;
          }

          return acc;
        }, sortedPolicies[0])
      : null;

  return {
    isLoading,
    data: sortedPolicies?.map(fromApiPolicy((policyId) => policyId === sortedPolicies?.[0]?.id)),
    cheapestPolicy: cheapestPolicy ? fromApiPolicy(() => false)(cheapestPolicy) : null,
  };
};
