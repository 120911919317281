import { atom, selector } from 'recoil';

import { HomeInsuranceForm } from '../types/home-insurance';

export const homeInsuranceState = atom<HomeInsuranceForm>({
  key: 'homeInsuranceValues',
  default: {
    itemsType: 'standard',
    insuredItems: ['building', 'content', 'neighbour'],
    buildingType: 'apartment',
    constructionType: 'solid',
  },
});

export const itemsTypeState = selector({
  key: 'itemsType',
  get: ({ get }) => {
    const homeInsurance = get(homeInsuranceState);

    return homeInsurance.itemsType;
  },
});

export const buildingTypeState = selector({
  key: 'buildingType',
  get: ({ get }) => {
    const homeInsurance = get(homeInsuranceState);

    return homeInsurance.buildingType;
  },
});

export const constructionTypeState = selector({
  key: 'constructionType',
  get: ({ get }) => {
    const homeInsurance = get(homeInsuranceState);

    return homeInsurance.constructionType;
  },
});

export const insuredItemsState = selector({
  key: 'insuredItems',
  get: ({ get }) => {
    const homeInsurance = get(homeInsuranceState);

    return homeInsurance.insuredItems;
  },
});
