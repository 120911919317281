import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';

import { Typography } from '@mui/material';

import IconRadio from '../../../components/form/icon-radio';
import { FormNavigation } from '../../../components/form-navigation';
import { Building } from '../../../components/icons/building';
import { Content } from '../../../components/icons/content';
import { Housing } from '../../../components/icons/housing';
import { LogoColored } from '../../../components/icons/logo-colored';
import { Neighbour } from '../../../components/icons/neighbour';
import {
  buildingTypeState,
  constructionTypeState,
  homeInsuranceState,
  insuredItemsState,
} from '../../../stores/home-insurance';
import { useHomeInsuranceRequestDetails } from '../../../stores/use-home-insurance-request-details';
import { useSetRecoilKeyValue } from '../../../stores/use-set-recoil-key-value';
import { InsuredOption } from '../../../types/home-insurance';
import { useIsTabletOrLarger } from '../../../util/useIsTabletOrLarger';
import { useNavigatePreserveQuery } from '../../../util/useNavigatePreserveQuery';
import { useBuildingData } from '../preview-policy/hooks/use-building-data';
import { usePolicySuggestions } from '../results/hooks/use-policy-suggestions';
import {
  BaseContainer,
  ChoicesContainer,
  MixedConstructionMessageContainer,
  TitleText,
} from '../styled';

export const Extras = () => {
  const selectedInsuredItems = useRecoilValue(insuredItemsState);

  const setKeyValue = useSetRecoilKeyValue(homeInsuranceState);

  const buildingType = useRecoilValue(buildingTypeState);
  const constructionType = useRecoilValue(constructionTypeState);

  const isMixedConstruction = buildingType === 'house' && constructionType === 'mixed';

  const requestDetails = useHomeInsuranceRequestDetails();

  const navigate = useNavigatePreserveQuery();

  const isTabletOrLarger = useIsTabletOrLarger();

  const itemsPerRow = isTabletOrLarger ? 4 : 2;

  const onSubmit = useCallback(() => {
    navigate('../results', {
      extraParams: {
        rid: requestDetails,
      },
      exclude: ['area', 'constructionType', 'city'],
    });
  }, [navigate, requestDetails]);

  const isItemActive = useCallback(
    (item: InsuredOption) => selectedInsuredItems.includes(item),
    [selectedInsuredItems],
  );

  const toggleInsuredItem = (item: InsuredOption) => () => {
    if (isItemActive(item)) {
      setKeyValue(
        'insuredItems',
        selectedInsuredItems.filter((i) => i !== item),
      );
      return;
    }

    setKeyValue('insuredItems', [...selectedInsuredItems, item]);
  };

  const buildingData = useBuildingData();

  const { data } = usePolicySuggestions(
    buildingData?.area ?? 0,
    'all',
    [],
    buildingData?.itemsType,
    !isMixedConstruction,
    selectedInsuredItems,
  );

  const seeOffersMessage = `Види ги понудите ${data && data.length > 0 ? `(${data.length})` : ''}`;

  const onBack = useCallback(() => {
    navigate(buildingType === 'apartment' ? '../building' : '../construction');
  }, [navigate, buildingType]);

  return (
    <>
      <BaseContainer>
        <LogoColored />
        <TitleText variant="h4" color="primary" fontWeight="bold" align="center">
          {isMixedConstruction
            ? 'Упс… Ова нема да можеме да го направиме онлајн :('
            : 'Да го избереме најдоброто домаќинско за тебе, заедно!'}
        </TitleText>
        {isMixedConstruction ? (
          <MixedConstructionMessageContainer>
            <Typography variant="subtitle1" color="primary.dark" marginTop={3}>
              За жал осигурувањето на објекти од мешана градба не може да биде спроведено онлајн и
              за истото мора да се изврши увид во објектот од страна на проценител од осигурителната
              компанија пред да се прими во осигурување.
            </Typography>
            <Typography variant="subtitle1" color="primary.dark" marginTop={3}>
              Но Компа ви стои на располагање да ви помогне во процесот. Јавете се во нашиот Контакт
              центар на <a href="tel:070268000">070 268 000</a> и нашите вработени ќе ви закажат
              проценка во време кое ви одговара.
            </Typography>
          </MixedConstructionMessageContainer>
        ) : (
          <>
            <Typography variant="subtitle1" color="primary.dark" marginTop={3}>
              Кај објектот да биде осигурено:
            </Typography>
            <ChoicesContainer
              gridTemplateColumns={`repeat(${itemsPerRow}, 1fr)`}
              marginTop={2}
              sx={{
                rowGap: 2,
              }}
            >
              <IconRadio<InsuredOption>
                value="building"
                label="Градежен дел"
                icon={Building}
                active={isItemActive('building')}
                onClick={toggleInsuredItem('building')}
                description="Станбени обекти (станови и
              индивидуални згради) како
              градежни обекти со вградената
              електрична, топловодна,
              водоводна и канализациона
              инсталациа кои служат за живеење."
                actionPoint="Задолжително"
                disabled
              />
              <IconRadio<InsuredOption>
                value="content"
                label="Ствари во станот"
                icon={Content}
                active={isItemActive('content')}
                onClick={toggleInsuredItem('content')}
                description="Подвижните ствари на
            домаќинството кои се наоѓаат во
            осигурениот станбен обект, му
            служат и се во сопственост на
            осигуреникот или членовите на
            неговото семено домаќинство. Во
            ствари на домаќинството се
            сметаат стварите кои служат за
            уредување на станот и стварите за
            лична употреба."
                actionPoint="Задолжително"
                disabled
              />
              <IconRadio<InsuredOption>
                value="housing"
                label="Нужно сместување"
                icon={Housing}
                active={isItemActive('housing')}
                onClick={toggleInsuredItem('housing')}
                description="Трошоците за нужно сместување на
            Осигуреникот и членовите на
            неговото семено домаќинство при
            тотална штета, односно кога станот
            е оштетен до степен да не е
            погоден за живеење."
              />
              <IconRadio<InsuredOption>
                value="neighbour"
                label="Штети кон соседи"
                icon={Neighbour}
                active={isItemActive('neighbour')}
                onClick={toggleInsuredItem('neighbour')}
                description="Со осигурувањето се покриени
            штети на трети лица како
            последица на штета што ќе настане
            кај осигуреникот од осигурените
            ризици."
                actionPoint="Препорачано"
              />
            </ChoicesContainer>
          </>
        )}
      </BaseContainer>
      <FormNavigation
        onNext={!isMixedConstruction ? onSubmit : undefined}
        nextLabel={!isMixedConstruction ? seeOffersMessage : undefined}
        prevLabel={isTabletOrLarger ? 'Претходно прашање' : 'Претходно'}
        onPrev={onBack}
      />
    </>
  );
};
