import Carousel from 'react-multi-carousel';
import styled from 'styled-components';

import { Box, Typography } from '@mui/material';

import { Review as ReviewType } from '../../types';
import { Review } from '../review';

interface Props {
  reviews: Array<ReviewType>;
  label: string;
}

const CustomDot = styled.div<{ active: boolean }>`
  width: ${({ theme }) => theme.spacing(1)};
  height: ${({ theme }) => theme.spacing(1)};
  border-radius: 50%;
  background-color: ${({ active, theme }) =>
    active ? theme.palette.accent.main : theme.palette.grey[400]};
  margin: 0 ${({ theme }) => theme.spacing(0.5)};
`;

export const Reviews = ({ reviews, label }: Props) => {
  const averageRating =
    reviews.length === 0
      ? 0
      : reviews.reduce((acc, review) => acc + review.grade, 0) / reviews.length;

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      width="100%"
      padding={2}
      boxSizing="border-box"
    >
      {reviews.length === 0 ? (
        <Typography variant="body1" fontWeight="bold" color="primary.dark">
          Нема коментари за {label}
        </Typography>
      ) : null}
      {reviews.length !== 0 ? (
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
          <Typography variant="body1" fontWeight="bold" color="primary.dark">
            {label}
          </Typography>
          <Typography variant="body1" fontWeight="bold" color="primary.dark">
            {averageRating === 0 ? averageRating : averageRating.toFixed(1)}
          </Typography>
        </Box>
      ) : null}
      {reviews.length > 0 ? (
        <Box display="grid" gridTemplateColumns="1fr" width="100%">
          <Carousel
            swipeable={false}
            showDots
            arrows={false}
            responsive={{
              desktop: {
                breakpoint: { max: 4000, min: 1280 },
                items: 1,
              },
              tablet: {
                breakpoint: { max: 1279, min: 640 },
                items: 1,
              },
              mobile: {
                breakpoint: { max: 640, min: 0 },
                items: 1,
              },
            }}
            // @ts-ignore
            customDot={<CustomDot />}
          >
            {reviews.map((review) => (
              <Review key={review.id} review={review} />
            ))}
          </Carousel>
        </Box>
      ) : null}
    </Box>
  );
};
