import { SvgIconComponent } from '@mui/icons-material';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Flood: SvgIconComponent = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 256 256">
    <path
      d="M56.95,95.76c-2.34,0-3.99,0.02-5.65,0c-7.71-0.09-12.9-3.96-14.85-11.09c-2.01-7.36,0.04-13.87,6.13-18.87
    c17.93-14.72,35.9-29.38,53.85-44.06c7.1-5.81,14.2-11.64,21.33-17.41c7.12-5.75,13.26-5.8,20.28-0.07
    c24.99,20.38,49.88,40.9,74.95,61.15c11.22,9.06,8.05,24.25-0.96,28.71c-2.3,1.14-5.04,1.24-7.6,1.6c-1.6,0.22-3.25,0.04-5.3,0.04
    c0,1.9,0,3.34,0,4.77c0,7.34-0.78,14.82,0.2,22c1.21,8.87-2.97,12.61-8.93,15.81c-14.52,7.8-28.64,7.18-42.45-2.17
    c-3.15-2.13-6.23-4.52-9.61-6.03c-8.56-3.83-17-3.16-24.9,2.37c-7.4,5.17-14.92,9.7-23.85,10.81c-9.63,1.2-18.2-1.64-26.62-6.6
    c-4.69-2.76-6.58-6.07-6.15-12.15c0.56-7.96,0.14-16.01,0.14-24.02C56.95,99.12,56.95,97.68,56.95,95.76z"
    />
    <path
      d="M0,183.56c0.02-6.23,3.24-12,8.53-13.84c9.51-3.31,18.29-8,26.27-14.74c4.38-3.7,9.25-3.74,14.1-0.89
    c4.51,2.65,8.86,5.72,13.52,7.98c19.35,9.41,38,7.48,55.52-5.57c7.05-5.25,13.57-5.35,20.41-0.02
    c8.79,6.86,18.6,10.61,29.23,11.32c11.2,0.75,21.57-2.84,31.4-8.6c2.84-1.66,5.53-3.65,8.37-5.3c4.61-2.69,9.32-2.56,13.47,0.88
    c8.21,6.82,17.18,11.67,26.91,15.1c6.35,2.24,9.65,10.91,7.71,18.44c-1.99,7.73-8.87,13.33-15.52,11.09
    c-8.23-2.78-16.15-6.79-24.03-10.69c-2.15-1.06-3.64-1.59-5.8-0.26c-12.22,7.51-25.47,11.42-39.26,11.25
    c-14-0.17-27.5-4.11-39.96-11.62c-2.17-1.31-3.75-1.16-5.81,0.06c-12.28,7.28-25.53,11.19-39.27,11.54
    c-13.05,0.33-25.74-2.88-37.3-10.09c-3.48-2.17-6-1.9-9.63,0.13c-6.97,3.88-14.35,7.08-21.83,9.49
    C8.09,202.09-0.04,194.2,0,183.56z"
    />
    <path
      d="M0,239.61c0.03-6.22,3.27-11.98,8.57-13.81c9.52-3.29,18.29-8.04,26.27-14.76c4.26-3.59,9.05-3.73,13.79-1
    c4.53,2.61,8.87,5.71,13.52,8c19.46,9.57,38.21,7.68,55.86-5.49c7.06-5.27,13.6-5.24,20.41,0.08
    c8.79,6.86,18.62,10.58,29.25,11.28c11.2,0.74,21.57-2.89,31.4-8.65c3.05-1.78,5.97-3.84,9.08-5.48c4.18-2.2,8.55-1.94,12.24,0.97
    c8.63,6.8,17.87,11.9,27.93,15.54c6.12,2.21,9.05,11.1,7.02,18.46c-2.08,7.53-8.87,12.97-15.39,10.76
    c-8.23-2.79-16.15-6.79-24.05-10.68c-2.13-1.05-3.61-1.65-5.8-0.3c-12.1,7.46-25.24,11.35-38.91,11.26
    c-14.13-0.09-27.74-4.06-40.32-11.61c-2.16-1.3-3.75-1.17-5.81,0.05c-12.28,7.28-25.53,11.19-39.28,11.55
    c-13.18,0.34-25.95-3.01-37.65-10.25c-3.22-2-5.57-1.79-9,0.1c-7.1,3.91-14.55,7.23-22.15,9.69C8.06,258.2-0.05,250.26,0,239.61z"
    />
  </SvgIcon>
);

Flood.muiName = 'Flood';
