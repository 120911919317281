import { SvgIconComponent } from '@mui/icons-material';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Landslide: SvgIconComponent = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 23 12">
    <path d="M13.5 0L9.75 5L12.6 8.8L11 10C9.31 7.75 6.5 4 6.5 4L0.5 12H22.5L13.5 0Z" />
  </SvgIcon>
);

Landslide.muiName = 'Landslide';
