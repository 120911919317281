import { add } from 'date-fns';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { ArrowForward } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

import { PurchaseFormValues } from './types';
import { PolicySuggestion } from '../../../results/types';
import { useBuildingData } from '../../hooks/use-building-data';
import { useCreateOrder } from '../../hooks/use-create-order';

interface Props {
  policy: PolicySuggestion;
}

export const PurchaseForm = ({ policy }: Props) => {
  const buildingData = useBuildingData();

  const {
    control,
    setValue,
    handleSubmit,
    formState: { isValid },
    watch,
  } = useForm<PurchaseFormValues>({
    defaultValues: {
      ownerFirstName: '',
      ownerLastName: '',
      address: '',
      objectSize: '',
      ownerIdNumber: '',
      email: '',
      phoneNumber: '',
      insuranceStartDate: null,
      policyTermsAndConditions: false,
      dataProcessingAgreement: false,
      contactAgreement: false,
      permanentlyInhabited: false,
      isAddressSameAsOwner: true,
      ownerAddress: '',
    },
  });

  const watchIsAddressSame = watch('isAddressSameAsOwner');
  const watchObjectSize = watch('objectSize');

  useEffect(() => {
    if (buildingData?.area && !watchObjectSize) {
      setValue('objectSize', buildingData.area.toString());
    }
  }, [buildingData, watchObjectSize, setValue]);

  const { createOrder, isSuccess, isError, reset } = useCreateOrder(policy);

  return (
    <Box
      display="flex"
      flexDirection="column"
      component="form"
      onSubmit={handleSubmit(createOrder)}
      maxWidth="960px"
      marginTop={4}
      marginLeft="auto"
      marginRight="auto"
      width="100%"
      boxSizing="border-box"
    >
      <Box
        display="flex"
        flexDirection="column"
        borderRadius={4}
        padding={3}
        sx={{
          backgroundColor: 'accent.light',
          boxShadow: 1,
        }}
      >
        <Typography variant="h6" color="primary.dark">
          Ваши лични податоци
        </Typography>
        <Box display="flex" marginTop={2}>
          <Controller
            control={control}
            name="ownerFirstName"
            rules={{
              required: true,
            }}
            render={({ field, fieldState }) => (
              <TextField {...field} error={!!fieldState.error} placeholder="Име" />
            )}
          />
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            name="ownerLastName"
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                error={!!fieldState.error}
                placeholder="Презиме"
                sx={{
                  marginLeft: 2,
                }}
              />
            )}
          />
        </Box>
        <Box paddingLeft={3}>
          <Typography variant="body1" color="primary.dark" fontWeight={300}>
            (на сопственикот на објектот)
          </Typography>
        </Box>
        <Box display="grid" gridTemplateColumns="3fr 1fr 1fr" marginTop={2} alignItems="center">
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            name="address"
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                error={!!fieldState.error}
                placeholder="Целосна адреса и број на објектот што се осигурува"
                fullWidth
              />
            )}
          />
          <Controller
            control={control}
            name="objectSize"
            disabled
            rules={{
              required: true,
              validate: (value) => !Number.isNaN(Number(value)) || 'Внесете валидна квадратура',
            }}
            render={({ field, fieldState }) => (
              <TextField
                disabled
                {...field}
                error={!!fieldState.error}
                placeholder="Квадратура"
                sx={{
                  marginLeft: 2,
                }}
              />
            )}
          />
          <Typography variant="body1" color="grey.400" marginLeft={1}>
            м2
          </Typography>
        </Box>
        <Stack paddingLeft={3} direction="row" alignItems="center">
          <Typography variant="body1" color="primary.dark" fontWeight={300}>
            (како што е во Имотен лист)
          </Typography>
          <Controller
            control={control}
            name="isAddressSameAsOwner"
            render={({ field }) => (
              <FormControlLabel
                sx={{
                  marginLeft: 2,
                }}
                control={<Checkbox />}
                {...field}
                checked={field.value}
                onChange={(_, checked) => field.onChange(checked)}
                label={
                  <Typography variant="body1" fontSize={14} color="grey.400">
                    Адресата на објектот е иста со адресата од лична карта на сопственикот
                  </Typography>
                }
              />
            )}
          />
        </Stack>
        {!watchIsAddressSame ? (
          <Box display="grid" gridTemplateColumns="3fr 1fr 1fr" marginTop={2}>
            <Controller
              control={control}
              name="ownerAddress"
              rules={{
                validate: (value, { isAddressSameAsOwner }) =>
                  isAddressSameAsOwner
                    ? true
                    : (value && value !== '') ||
                      'Потребно е да ја внесете адресата на сопственикот',
              }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  error={!!fieldState.error}
                  placeholder="Целосна адреса и број на сопственикот"
                  fullWidth
                />
              )}
            />
          </Box>
        ) : null}
        <Box display="grid" gridTemplateColumns="3fr 1fr 1fr" marginTop={2} alignItems="center">
          <Controller
            control={control}
            rules={{
              required: true,
              validate: (value) =>
                (!Number.isNaN(Number(value)) && value.length === 13) || 'Внесете валиден ЕМБГ',
            }}
            name="ownerIdNumber"
            render={({ field, fieldState }) => (
              <TextField {...field} error={!!fieldState.error} placeholder="ЕМБГ" fullWidth />
            )}
          />
        </Box>
        <Box paddingLeft={3}>
          <Typography variant="body1" color="primary.dark" fontWeight={300}>
            (потребно за ваша идентификација како овластено лице во случај на штета)
          </Typography>
        </Box>
        <Box
          display="grid"
          gridTemplateColumns="3fr 2fr 1fr"
          gridTemplateRows="auto auto"
          marginTop={2}
          alignItems="center"
        >
          <Controller
            control={control}
            name="email"
            rules={{
              required: true,
            }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                error={!!fieldState.error}
                placeholder="Ваша мејл адреса"
                fullWidth
              />
            )}
          />
          <Controller
            control={control}
            name="phoneNumber"
            rules={{
              required: true,
              validate: (value) =>
                (value.length === 9 && !Number.isNaN(Number(value))) || 'Внесете валиден број',
            }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                error={!!fieldState.error}
                placeholder="Ваш мобилен број"
                fullWidth
                sx={{
                  marginLeft: 2,
                }}
              />
            )}
          />
          <Box />
          <Box paddingLeft={3}>
            <Typography variant="body1" color="primary.dark" fontWeight={300}>
              (за достава на полисата)
            </Typography>
          </Box>
          <Box paddingLeft={3}>
            <Typography variant="body1" color="primary.dark" fontWeight={300}>
              (за побрза комуникација во случај на потреба)
            </Typography>
          </Box>
          <Box />
        </Box>
        <Box display="grid" gridTemplateColumns="2.5fr 1fr 1fr" marginTop={2} alignItems="center">
          <Controller
            control={control}
            name="insuranceStartDate"
            rules={{
              required: true,
            }}
            render={({ field, fieldState }) => (
              <DatePicker
                format="dd/MM/yyyy"
                disablePast
                minDate={add(new Date(), { days: 1 })}
                {...field}
                label="Датум на почеток на осигурувањето"
                sx={{
                  '.MuiInputAdornment-root': {
                    position: 'absolute',
                    right: (theme) => theme.spacing(2),
                  },
                }}
                slotProps={{
                  textField: {
                    error: !!fieldState.error,
                  },
                }}
              />
            )}
          />
          <Button
            variant="contained"
            color="accent"
            size="large"
            sx={{
              marginLeft: 4,
              width: 'max-content',
            }}
            onClick={() =>
              setValue('insuranceStartDate', add(new Date(), { days: 1 }), { shouldValidate: true })
            }
          >
            Првиот можен
          </Button>
        </Box>
        <Box paddingLeft={3}>
          <Typography variant="body1" color="primary.dark" fontWeight={300}>
            (во формат ДД/ММ/ГГГГ)
          </Typography>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" marginTop={4} gap={1}>
        <Controller
          control={control}
          name="policyTermsAndConditions"
          rules={{
            validate: (value) => value || 'Потребно е да ги прифатите условите на осигурување',
          }}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox />}
              {...field}
              onChange={(_, checked) => field.onChange(checked)}
              label={
                <Typography variant="body1" fontSize={14} color="grey.400">
                  Ги прочитав и запознаен/а сум со деталите за полисата, како и со Условите на
                  осигурување (.pdf) врз основа на кои се склучува ова осигурување.
                </Typography>
              }
            />
          )}
        />
        <Controller
          control={control}
          name="dataProcessingAgreement"
          rules={{
            validate: (value) => value || 'Потребно е да се согласите за процесирање на податоци',
          }}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox />}
              {...field}
              onChange={(_, checked) => field.onChange(checked)}
              label={
                <Typography variant="body1" fontSize={14} color="grey.400">
                  Се согласувам Компа да ги проследи моите податоци до брокерското друштво Оне
                  Брокер, со цел издавање на избраното осигурување.
                </Typography>
              }
            />
          )}
        />
        <Controller
          control={control}
          name="permanentlyInhabited"
          rules={{
            validate: (value) => value || 'Потребно е да потврдите дека објектот е трајно населен',
          }}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox />}
              {...field}
              onChange={(_, checked) => field.onChange(checked)}
              label={
                <Typography variant="body1" fontSize={14} color="grey.400">
                  Потврдувам дека објектот е трајно населен, односно дека не е ненаселен, не е
                  викендица или објект кој се изнајмува за краток престој (AirBnb, стан на ден и
                  сл.) и дека во време на нарачка на полисата не е настаната штета на предметите во
                  станот од некој од осигурените ризици. (Доколку ова не е случај, јавете се во
                  нашиот кориснички центар на 070 268 000 за асистенција при вадење на
                  осигурувањето, бидејќи истото нема да може да биде спроведено онлајн)
                </Typography>
              }
            />
          )}
        />
        <Controller
          control={control}
          name="contactAgreement"
          rules={{
            validate: (value) => value || 'Потребно е да се согласите за да бидете контактирани',
          }}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox />}
              {...field}
              onChange={(_, checked) => field.onChange(checked)}
              label={
                <Typography variant="body1" fontSize={14} color="grey.400">
                  Се согласувам Компа да ме контактира со информации за истекување на важноста на
                  полисата, како и информации за поволности при обнова и вадење на ист или друг тип
                  осигурување.
                </Typography>
              }
            />
          )}
        />
      </Box>
      <Stack
        spacing={2}
        marginTop={4}
        borderRadius={4}
        padding={2}
        sx={{
          backgroundColor: '#F5F5F5',
        }}
      >
        <Typography variant="h6" fontWeight="bold" color="primary.dark">
          Што е следно?
        </Typography>
        <Typography variant="body1" color="grey.600">
          Се прави проверка на податоците, по што ти испраќаме мејл со потврда дека полисата може да
          биде изработена и линк за плаќање на полисата (просечно време за потврда во работен ден -
          до 1 час).
        </Typography>
        <Typography variant="body1" color="grey.600">
          По евидентираната уплата, полисата се изработува и ти се доставува електронски примерок
          (истиот или најдоцна следниот работен ден).
        </Typography>
        <Typography variant="body1" color="grey.600">
          Важноста на полисата започнува по истекот на 24-от час на денот на издавање, освен за
          ризици за кои е децидно наведено поинаку во условите на осигурување.
        </Typography>
        <Typography variant="body1" color="grey.600">
          Во случај на штета, истата се пријавува во осигурителната компанија. Компа заедно со
          полисата ќе ти достави упатство за пријава на штета за компанија која ја издава полиста и
          ти стои на располагање за сите дополнителни информации.
        </Typography>
      </Stack>
      <Box display="flex" justifyContent="flex-end" alignItems="center" marginY={6}>
        <Button
          variant="contained"
          color="accent"
          size="large"
          type="submit"
          disabled={!isValid}
          endIcon={
            <ArrowForward
              sx={{
                color: 'primary.dark',
              }}
            />
          }
          sx={{
            fontWeight: 'bold',
            fontSize: '1.25rem',
          }}
        >
          НАРАЧАЈ ЈА ПОЛИСАТА
        </Button>
      </Box>
      <Snackbar
        open={!!isError}
        onClose={reset}
        autoHideDuration={5000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Alert severity="error" variant="filled">
          Настана грешка при креирањето на нарачката
        </Alert>
      </Snackbar>
      <Snackbar
        open={!!isSuccess}
        onClose={reset}
        autoHideDuration={5000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Alert severity="success" variant="filled">
          Успешно креиравте нарачка
        </Alert>
      </Snackbar>
    </Box>
  );
};
