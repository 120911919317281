import React, { useState } from 'react';

import {
  CarCrash,
  Check,
  Clear,
  FlashOn,
  Flight,
  Thunderstorm,
  Water,
  Whatshot,
} from '@mui/icons-material';
import { Box, IconButton, SvgIcon, Tab, Tabs, Typography } from '@mui/material';

import { Container } from './styled';
import { IconFilled } from '../../../../../components/icon-filled';
import { Accident } from '../../../../../components/icons/accident';
import { Aquarium } from '../../../../../components/icons/aquarium';
import { ArrowReturn } from '../../../../../components/icons/arrow-return';
import { AtmosphereWaters } from '../../../../../components/icons/atmosphere-waters';
import { Avalanche } from '../../../../../components/icons/avalanche';
import { Burglar } from '../../../../../components/icons/burglar';
import { Defect } from '../../../../../components/icons/defect';
import { Earthquake } from '../../../../../components/icons/earthquake';
import { Explosion } from '../../../../../components/icons/explosion';
import { Glass } from '../../../../../components/icons/glass';
import { HomeRepairs } from '../../../../../components/icons/home-repairs';
import { Ice } from '../../../../../components/icons/ice';
import { Installation } from '../../../../../components/icons/installation';
import { Landslide } from '../../../../../components/icons/landslide';
import { Pets } from '../../../../../components/icons/pets';
import { Protest } from '../../../../../components/icons/protest';
import { Snow } from '../../../../../components/icons/snow';
import { TravelInsurance } from '../../../../../components/icons/travel-insurance';
import { TreeFall } from '../../../../../components/icons/tree-fall';
import { WeatherHail } from '../../../../../components/icons/weather-hail';
import { PolicySuggestion, Review as ReviewType } from '../../types';
import { Reviews } from '../reviews';

interface ProsAndConsProps {
  pros: Array<string>;
  cons: Array<string>;
}

const ProsAndCons = ({ pros, cons }: ProsAndConsProps) => (
  <Box display="flex" flexDirection="column" overflow="visible" marginTop={1} padding={1}>
    {pros.map((pro, index) => (
      <Box key={pro} display="flex" alignItems="flex-start" marginTop={index !== 0 ? 1 : 0}>
        <IconFilled icon={Check} color="primary" active width="inherit" />
        <Typography variant="body1" color="primary.dark" marginLeft={1}>
          {pro}
        </Typography>
      </Box>
    ))}
    {cons.map((con) => (
      <Box key={con} display="flex" alignItems="flex-start" marginTop={1}>
        <IconFilled icon={Clear} color="accent" active width="inherit" />
        <Typography variant="body1" color="primary.dark" marginLeft={1}>
          {con}
        </Typography>
      </Box>
    ))}
  </Box>
);

const riskIcons: Record<string, typeof SvgIcon> = {
  fire: Whatshot,
  spillage: Water,
  theft: Burglar,
  explosion: Explosion,
  thunder: FlashOn,
  hail: WeatherHail,
  storm: Thunderstorm,
  flying_objects: Flight,
  protest: Protest,
  vehicle_crash: CarCrash,
  earthquake: Earthquake,
  snow_weight: Snow,
  landslide: Landslide,
  home_repairs: HomeRepairs,
  avalanche: Avalanche,
  tree_fall: TreeFall,
  vandalism: Burglar,
  atmosphere_wather: AtmosphereWaters,
  glass_break: Glass,
  aquarium: Aquarium,
  accident: Accident,
  defect: Defect,
  travel_insurance: TravelInsurance,
  pets: Pets,
  ice: Ice,
  installation: Installation,
};

interface PolicyScopeProps {
  risks: PolicySuggestion['risks'];
  subjects: Record<string, PolicySuggestion['subjects'][number]>;
}

export const SubjectTypeToLabelMapping: Record<string, string> = {
  CONSTRUCTION_PART: 'Градежен дел',
  ITEMS: 'Ствари на износ',
  THIRD_PARTY_DAMAGE: 'Износ кон соседи',
  ACCOMMODATION: 'Трошоци за сместување',
};

const risksToIgnore = ['theft', 'fire', 'spillage', 'explosion', 'flood'];

const riskShortLabels: Record<string, string> = {
  thunder: 'гром',
  storm: 'љуња',
  hail: 'град',
  protest: 'собири',
  flying_objects: 'летало',
  avalanche: 'лавина',
  landslide: 'одрон',
  vehicle_crash: 'судар',
  vandalism: 'вандализам',
  glass_break: 'стакло',
  aquarium: 'аквариум',
  snow_weight: 'снег',
  atmosphere_wather: 'а.води',
  tree_fall: 'пад дрво',
  travel_insurance: 'патничко о.',
  accident: 'незгода',
  home_repairs: 'поправки',
  pets: 'миленици',
  defect: 'квар',
  earthquake: 'земјотрес',
  ice: 'мраз',
  installation: 'инсталации',
};

const SUBJECTS_ORDER = ['CONSTRUCTION_PART', 'ITEMS', 'THIRD_PARTY_DAMAGE', 'ACCOMMODATION'];

const PolicyScope = ({ risks, subjects }: PolicyScopeProps) => (
  <Box
    display="flex"
    width="100%"
    flexDirection="column"
    alignItems="center"
    marginTop={1}
    padding={1}
    boxSizing="border-box"
  >
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography variant="subtitle1" textAlign="center">
        Максимална сума на исплата
      </Typography>
      <Box display="flex" flexDirection="column" alignItems="center">
        {SUBJECTS_ORDER.map((subject) => (
          <Typography variant="subtitle2" color="primary.dark" align="center">
            {SubjectTypeToLabelMapping[subject]}{' '}
            <b>{subjects[subject] ? `до ${subjects[subject].totalSum}€` : 'нема'}</b>
          </Typography>
        ))}
      </Box>
    </Box>
    <Box
      display="grid"
      gridTemplateColumns="repeat(4, 1fr)"
      marginTop={2}
      sx={{
        gridGap: (theme) => theme.spacing(1),
        alignItems: 'start',
        minWidth: '100%',
      }}
    >
      {risks
        .filter((risk) => !risksToIgnore.includes(risk.key))
        .map((risk) => (
          <IconFilled
            key={risk.key}
            icon={riskIcons[risk.key]}
            width="40px"
            active
            label={riskShortLabels[risk.key]}
            tooltip={risk.note}
            description={risk.descriptionTitle}
          />
        ))}
    </Box>
  </Box>
);

interface ReviewProps {
  reviews: Array<ReviewType>;
}

const ReviewsTab = ({ reviews }: ReviewProps) => {
  const easeOfIssuingReviews = reviews.filter((review) => review.type === 'EASE_OF_ISSUING');
  const damageReportReviews = reviews.filter((review) => review.type === 'DAMAGE_REPORT');

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Reviews reviews={easeOfIssuingReviews} label="Лесно издавање" />
      <Reviews reviews={damageReportReviews} label="Пријава на штета" />
    </Box>
  );
};

interface Props {
  visible: boolean;
  hideDetails: () => void;
  policy: PolicySuggestion;
}

export const PolicyDetails = ({ visible, hideDetails, policy }: Props) => {
  const [selectedTab, setSelectedTab] = useState(1);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const subjectMap = policy.subjects.reduce(
    (acc, subject) => {
      // eslint-disable-next-line no-param-reassign
      acc[subject.type] = subject;
      return acc;
    },
    {} as Record<string, PolicySuggestion['subjects'][number]>,
  );

  return (
    <Container visible={visible}>
      <Box display="flex" flexDirection="column" alignItems="center" overflow="hidden">
        <Tabs value={selectedTab} onChange={handleTabChange} centered>
          <Tab value={0} label="Pro/con" />
          <Tab value={1} label="Опфат" />
          <Tab value={2} label="Коментари" />
        </Tabs>
        {selectedTab === 0 ? <ProsAndCons pros={policy.pros} cons={policy.cons} /> : null}
        {selectedTab === 1 ? <PolicyScope risks={policy.risks} subjects={subjectMap} /> : null}
        {selectedTab === 2 ? <ReviewsTab reviews={policy.reviews} /> : null}
      </Box>
      <Box display="flex" flexDirection="column" justifyContent="flex-end">
        <Box width="100%" display="flex" justifyContent="center">
          <IconButton
            onClick={hideDetails}
            sx={{
              border: (theme) => `1px solid ${theme.palette.grey[100]}`,
            }}
          >
            <ArrowReturn />
          </IconButton>
        </Box>
        <Box
          marginTop={1}
          display="grid"
          sx={{
            gridTemplateColumns: '1fr 1fr',
          }}
          width="100%"
        >
          <Typography
            variant="body1"
            color="primary.dark"
            sx={{
              flexGrow: 1,
              flexBasis: 0,
            }}
          >
            Цена + подарок
          </Typography>
          <Box display="flex" flexDirection="column" alignItems="flex-end">
            <Typography
              variant="subtitle1"
              color="accent.main"
              align="right"
              sx={{
                flexGrow: 1,
                flexBasis: 0,
              }}
            >
              {policy.totalPremium} ден
            </Typography>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};
