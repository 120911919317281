import styled from 'styled-components';

import { Box } from '@mui/material';

export const AdvantagesContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.secondary.light};
  border-radius: ${({ theme }) => theme.spacing(3)};
  padding: ${({ theme }) => theme.spacing(4)};
`;
