import { useSearchParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { homeInsuranceState } from './home-insurance';
import { toBinary } from '../util/string';

export const useHomeInsuranceRequestDetails = () => {
  const [queryParams] = useSearchParams();

  const area = queryParams.get('area');
  const city = queryParams.get('city');

  const parsedArea = Number.parseInt(area ?? '0', 10);

  const homeInsurance = useRecoilValue(homeInsuranceState);

  const details = { ...homeInsurance, area: parsedArea, city };

  return toBinary(JSON.stringify(details));
};
