import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router';
import { createBrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import 'react-multi-carousel/lib/styles.css';

import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { HomeInsurance } from './pages/home-insurance';
import { BasicInfo } from './pages/home-insurance/basic-info';
import { BuildingType } from './pages/home-insurance/building-type';
import { ConstructionType } from './pages/home-insurance/construction-type';
import { Extras } from './pages/home-insurance/extras';
import { ItemsType } from './pages/home-insurance/items-type';
import { PolicyDetails } from './pages/home-insurance/policy-details';
import { PreviewPolicy } from './pages/home-insurance/preview-policy';
import { Results } from './pages/home-insurance/results';
import { InsuranceBase } from './pages/insurance-base';
import { Root } from './pages/root';
import { createTheme } from './styles/theme';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const theme = createTheme();

const queryClient = new QueryClient();

const GlobalStyles = createGlobalStyle`
  body {
    margin: 0;
  }

  .react-multi-carousel-item {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const router = createBrowserRouter(
  [
    {
      path: '/',
      element: <Root />,
    },
    {
      path: '/insurance',
      element: <InsuranceBase />,
      children: [
        {
          path: 'home',
          element: <HomeInsurance />,
          children: [
            {
              element: <BasicInfo />,
              index: true,
            },
            {
              path: 'building',
              element: <BuildingType />,
            },
            {
              path: 'construction',
              element: <ConstructionType />,
            },
            {
              path: 'items',
              element: <ItemsType />,
            },
            {
              path: 'extras',
              element: <Extras />,
            },
            {
              path: 'results',
              element: <Results />,
            },
            {
              path: 'policy/:policyId',
              element: <PreviewPolicy />,
            },
            {
              path: 'policy/:policyId/details',
              element: <PolicyDetails />,
            },
          ],
        },
      ],
    },
  ],
  { basename: '/app' },
);

root.render(
  <React.StrictMode>
    <RecoilRoot>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <MuiThemeProvider theme={theme}>
              <GlobalStyles />
              <RouterProvider router={router} />
            </MuiThemeProvider>
          </ThemeProvider>
        </QueryClientProvider>
      </LocalizationProvider>
    </RecoilRoot>
  </React.StrictMode>,
);
