import { Typography } from '@mui/material';

import { InsuranceTypeSelector } from './components/insurance-type-selector';
import { LeftContainer, MainContainer } from './styled';
import { Footer } from '../../components/footer';
import { Header } from '../../components/header';

export const Root = () => (
  <>
    <Header />
    <MainContainer>
      <LeftContainer>
        <Typography variant="h4" color="primary" fontWeight="bold" display="inline">
          Компарирај,{' '}
          <Typography variant="h4" color="primary.light" fontWeight="bold" display="inline">
            не комплицирај!
          </Typography>
        </Typography>
        <InsuranceTypeSelector />
      </LeftContainer>
    </MainContainer>
    <Footer />
  </>
);
