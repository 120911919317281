import { Outlet } from 'react-router';

import { Box } from '@mui/material';

import { Footer } from '../../components/footer';

export const InsuranceBase = () => (
  <>
    <Box minHeight="75.1vh">
      <Outlet />
    </Box>
    <Footer />
  </>
);
