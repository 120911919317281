import { ApiPolicy } from './types';
import { BuildingType, ConstructionType } from '../../types/home-insurance';
import { api } from '../instance';

export const fetchPolicySuggestions = (
  size: number,
  constructionType: ConstructionType,
  itemsType: 'lux' | 'standard',
  objectType: BuildingType,
) =>
  api
    .get<Array<ApiPolicy>>('/insurance-packages/suggest', {
      params: {
        size,
        constructionType,
        itemsType,
        objectType,
      },
    })
    .then(({ data }) => data);
