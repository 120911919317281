import { useMutation } from '@tanstack/react-query';

import { useBuildingData } from './use-building-data';
import { createOrder } from '../../../../api/orders';
import { CreatePurchaseRequestBody } from '../../../../api/orders/types';
import { PolicySuggestion } from '../../results/types';
import { PurchaseFormValues } from '../components/purchase-form/types';

const toRequestBody = (
  order: PurchaseFormValues,
  objectType: 'HOUSE' | 'APARTMENT',
  constructionType: 'SOLID' | 'MIXED',
  totalPrice: number,
  insurancePackageId: number,
): CreatePurchaseRequestBody => ({
  ownerFirstName: order.ownerFirstName,
  ownerLastName: order.ownerLastName,
  address: order.address,
  objectSize: Number.parseInt(order.objectSize, 10),
  ownerIdNumber: order.ownerIdNumber,
  email: order.email,
  phoneNumber: order.phoneNumber,
  insuranceStartDate: order.insuranceStartDate?.toISOString() || '',
  objectType,
  constructionType,
  totalPrice,
  insurancePackage: {
    id: insurancePackageId,
  },
  ownerAddress: order.isAddressSameAsOwner ? order.address : order.ownerAddress,
});

export const useCreateOrder = (policy: PolicySuggestion) => {
  const { mutateAsync, isSuccess, isError, reset } = useMutation<
    any,
    unknown,
    CreatePurchaseRequestBody
  >(createOrder);

  const buildingData = useBuildingData();

  const objectType =
    (buildingData?.buildingType?.toUpperCase() as 'APARTMENT' | 'HOUSE') ?? 'HOUSE';

  const constructionType =
    (buildingData?.constructionType?.toUpperCase() as 'SOLID' | 'MIXED') ?? 'SOLID';

  const createOrderMutation = (order: PurchaseFormValues) =>
    mutateAsync(toRequestBody(order, objectType, constructionType, policy.totalPremium, policy.id));

  return { createOrder: createOrderMutation, isSuccess, isError, reset };
};
