import { useMemo, useState } from 'react';

import { KeyboardArrowDown } from '@mui/icons-material';
import { AppBar, Button, Container, Menu, MenuItem, Toolbar, Typography } from '@mui/material';

import { InsuranceDropdownContainer, MenuItemsContainer } from './styled';

export const Header = () => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [aboutUsAnchorEl, setAboutUsAnchorEl] = useState<null | HTMLElement>(null);

  const isMenuOpen = useMemo(() => Boolean(menuAnchorEl), [menuAnchorEl]);

  const isAboutUsOpen = useMemo(() => Boolean(aboutUsAnchorEl), [aboutUsAnchorEl]);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const onMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleAboutUsClick = (event: React.MouseEvent<HTMLElement>) => {
    setAboutUsAnchorEl(event.currentTarget);
  };

  const onAboutUsClose = () => {
    setAboutUsAnchorEl(null);
  };

  return (
    <AppBar position="static">
      <Container maxWidth={false}>
        <Toolbar disableGutters>
          <Typography variant="h6" component="h1">
            Kompa
          </Typography>
          <InsuranceDropdownContainer>
            <Button
              variant="text"
              color="inherit"
              endIcon={<KeyboardArrowDown />}
              onClick={handleMenuClick}
            >
              Осигурување
            </Button>
            <Menu anchorEl={menuAnchorEl} open={isMenuOpen} onClose={onMenuClose}>
              <MenuItem onClick={onMenuClose}>Домаќинско</MenuItem>
              <MenuItem onClick={onMenuClose}>Здравствено</MenuItem>
            </Menu>
          </InsuranceDropdownContainer>
          <MenuItemsContainer>
            <Button
              variant="text"
              color="inherit"
              endIcon={<KeyboardArrowDown />}
              onClick={handleAboutUsClick}
            >
              За Компа
            </Button>
            <Menu anchorEl={aboutUsAnchorEl} open={isAboutUsOpen} onClose={onAboutUsClose}>
              <MenuItem onClick={onAboutUsClose}>За фирмата</MenuItem>
              <MenuItem onClick={onAboutUsClose}>Контакт</MenuItem>
              <MenuItem onClick={onAboutUsClose}>Мапа на сајтот</MenuItem>
            </Menu>
            <Button variant="text" color="inherit">
              Новости
            </Button>
            <Button variant="text" color="inherit">
              Кариера
            </Button>
          </MenuItemsContainer>
          <Button color="primary" variant="contained">
            Log in
          </Button>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
