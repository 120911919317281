import { SvgIconComponent } from '@mui/icons-material';
import { SvgIconProps, SvgIcon } from '@mui/material';

export const Pets: SvgIconComponent = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 21 19">
    <path d="M6.89092 0.0150431C8.07092 -0.154957 9.32092 1.13504 9.68092 2.91504C10.0409 4.68504 9.39092 6.26504 8.21092 6.44504C7.04092 6.62504 5.78092 5.33504 5.41092 3.55504C5.04092 1.78504 5.71092 0.205043 6.89092 0.0150431ZM14.0409 0.0150431C15.2309 0.205043 15.8909 1.78504 15.5409 3.55504C15.1609 5.33504 13.9109 6.62504 12.7309 6.44504C11.5409 6.26504 10.8909 4.68504 11.2609 2.91504C11.6209 1.13504 12.8709 -0.154957 14.0409 0.0150431ZM1.54092 4.61504C2.68092 4.12504 4.23092 5.01504 5.04092 6.56504C5.80092 8.14504 5.54092 9.80504 4.41092 10.295C3.28092 10.785 1.74092 9.90504 0.950922 8.33504C0.160922 6.76504 0.440922 5.09504 1.54092 4.61504ZM19.5409 4.61504C20.6409 5.09504 20.9209 6.76504 20.1309 8.33504C19.3409 9.90504 17.8009 10.785 16.6709 10.295C15.5409 9.80504 15.2809 8.14504 16.0409 6.56504C16.8509 5.01504 18.4009 4.12504 19.5409 4.61504ZM17.8709 15.395C17.9109 16.335 17.1909 17.375 16.3309 17.765C14.5409 18.585 12.4209 16.885 10.4309 16.885C8.44092 16.885 6.30092 18.655 4.54092 17.765C3.54092 17.275 2.85092 15.975 2.98092 14.895C3.16092 13.405 4.95092 12.605 6.01092 11.515C7.42092 10.105 8.42092 7.45504 10.4309 7.45504C12.4309 7.45504 13.4909 10.065 14.8409 11.515C15.9509 12.735 17.8009 13.765 17.8709 15.395Z" />
  </SvgIcon>
);

Pets.muiName = 'Pets';
