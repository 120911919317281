import styled from 'styled-components';

import { Box } from '@mui/material';

export const BasicInfoContainer = styled(Box)`
  border: 1px solid ${({ theme }) => theme.palette.accent.main};
  border-radius: ${({ theme }) => theme.spacing(3)};
  box-shadow: ${({ theme }) => theme.shadows[3]};
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  overflow: hidden;
`;

export const PriceContainer = styled(Box)`
  background-color: ${({ theme }) => theme.palette.accent.light};
  border-top: 1px solid ${({ theme }) => theme.palette.accent.main};
  padding: ${({ theme }) => theme.spacing(2, 4)};
`;

export const Logo = styled.img`
  height: ${({ theme }) => theme.spacing(9)};
`;
