import { useMemo, useState } from 'react';
import { Navigate, useLocation, useParams } from 'react-router';

import { Search } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { useClickAway } from '@uidotdev/usehooks';

import { Item, ItemDetails } from './components/ItemDetails';
import { DetailsContainer } from './styled';
import { LogoColored } from '../../../components/icons/logo-colored';
import { useNavigatePreserveQuery } from '../../../util/useNavigatePreserveQuery';
import { usePolicyData } from '../preview-policy/hooks/use-policy-data';
import { CompanyLogoMap } from '../results/components/policy/constants';
import { CompanyLogo } from '../results/components/policy/styled';
import { SubjectTypeToLabelMapping } from '../results/components/policy-details';
import { PolicySuggestion } from '../results/types';
import { TitleText } from '../styled';

type RisksDetailsItems = Array<Item<PolicySuggestion['risks'][number]>>;

type SubjectsDetailsItems = Array<Item<PolicySuggestion['subjects'][number]>>;

const subjectsDetailsItems: SubjectsDetailsItems = [
  { title: 'Исклучоци', key: 'exception' },
  { title: 'Лимити', key: 'limit' },
  { title: 'Франшиза', key: 'deductible' },
  { title: 'Каренца', key: 'gracePeriod' },
];

const risksDetailsItems: RisksDetailsItems = [
  { title: 'Опис на ризик', key: 'description' },
  { title: 'Исклучоци', key: 'exception' },
  { title: 'Лимити', key: 'limit' },
  { title: 'Франшиза', key: 'deductible' },
  { title: 'Каренца', key: 'gracePeriod' },
];

export const PolicyDetails = () => {
  const navigate = useNavigatePreserveQuery();
  const location = useLocation();

  const containerRef = useClickAway((e) => {
    if (e.target instanceof HTMLAnchorElement) {
      return;
    }
    navigate(location.pathname.replace('/details', ''));
  });

  const { policyId: policyIdParam } = useParams<{ policyId: string }>();

  const policyId = Number.parseInt(policyIdParam ?? '', 10);

  const { policy, isLoading } = usePolicyData(policyId);

  const [selectedTab, setSelectedTab] = useState<'risks' | 'subjects'>('risks');

  const [searchValue, setSearchValue] = useState('');

  const filteredRisks = useMemo(
    () =>
      policy?.risks.filter(
        (risk) =>
          risk.name?.toLowerCase().includes(searchValue.toLowerCase()) ||
          risk.description?.toLowerCase().includes(searchValue.toLowerCase()) ||
          risk.exception?.toLowerCase().includes(searchValue.toLowerCase()),
      ),
    [policy, searchValue],
  );

  const filteredSubjects = useMemo(
    () =>
      policy?.subjects.filter(
        (subject) =>
          SubjectTypeToLabelMapping[subject.type]
            ?.toLowerCase()
            .includes(searchValue.toLowerCase()) ||
          subject.type?.toLowerCase().includes(searchValue.toLowerCase()) ||
          subject.exception?.toLowerCase().includes(searchValue.toLowerCase()),
      ),
    [policy, searchValue],
  );

  const handleTabChange = (e: React.SyntheticEvent<any>, v: 'risks' | 'subjects' | 'terms') => {
    if (v === 'terms') {
      return;
    }

    setSelectedTab(v);
  };

  if (!policyId || Number.isNaN(policyId)) {
    return <Navigate to="../../" />;
  }

  if (!policy || isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  const logoSource = CompanyLogoMap[policy.company];

  return (
    <Box display="flex" alignItems="center" flexDirection="column" paddingTop={3}>
      <Box display="flex" justifyContent="center">
        <LogoColored />
      </Box>
      <Box display="flex" justifyContent="center">
        <TitleText variant="h4" color="primary" fontWeight="bold" align="center">
          Сите детали за одбраната понуда
        </TitleText>
      </Box>
      <DetailsContainer
        display="flex"
        flexDirection="column"
        alignItems="center"
        ref={containerRef}
      >
        <Box display="flex" flexDirection="column" alignItems="flex-start" width="100%">
          <CompanyLogo src={logoSource} />
          <Typography variant="caption" color="primary.dark" marginTop={1}>
            {policy.name}
          </Typography>
        </Box>
        <Box marginTop={2}>
          <Tabs value={selectedTab} onChange={handleTabChange} centered>
            <Tab value="risks" label="Ризици" />
            <Tab value="subjects" label="Предмет на осигурување" />
            <Tab value="terms" label="Општи услови на осигурување" />
          </Tabs>
        </Box>
        <Box width="100%" display="flex" justifyContent="center" alignItems="center">
          <TextField
            label="Пребарај"
            variant="outlined"
            fullWidth
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            margin="normal"
            InputProps={{
              endAdornment: (
                <IconButton>
                  <Search />
                </IconButton>
              ),
            }}
          />
        </Box>
        <Box marginTop={4} width="100%" display="flex" flexDirection="column">
          {selectedTab === 'risks'
            ? filteredRisks?.map((risk, index) => (
                <ItemDetails
                  key={risk.key}
                  openByDefault={index === 0}
                  data={risk}
                  items={risksDetailsItems}
                  title={risk.name}
                />
              ))
            : null}
          {selectedTab === 'subjects'
            ? filteredSubjects?.map((subject, index) => (
                <ItemDetails
                  key={subject.type}
                  openByDefault={index === 0}
                  data={subject}
                  items={subjectsDetailsItems}
                  title={SubjectTypeToLabelMapping[subject.type]}
                  openKeys={['exception', 'limit']}
                />
              ))
            : null}
        </Box>
      </DetailsContainer>
      <Box width="100%" display="flex" justifyContent="center" marginBottom={6}>
        <Button
          variant="text"
          sx={{
            color: 'primary.dark',
          }}
          target="__blank"
          href="https://mk.sava.insure/media/store/mk-MK/2021/Uslovi-1609/16.09.2021-Posebni-uslovi-za-domakjinstvo.pdf"
        >
          Преземи ги комплетните услови за ова осигурување
        </Button>
      </Box>
    </Box>
  );
};
