import { ColorPartial, PaletteColor } from '@mui/material/styles/createPalette';

export const BLUE: ColorPartial = {
  700: '#092854',
  500: '#1A7CAF',
  400: '#3189B6',
  300: '#529CC3',
  200: '#8CBDD7',
  100: '#C6DEEB',
  50: '#E7F2F7',
};

export const TEAL: ColorPartial = {
  600: '#22939B',
  A400: '#47C2CB',
  500: '#59C8D0',
  300: '#74D1D8',
  200: '#A2E0E4',
  100: '#D1EFF2',
  50: '#ECF9F9',
};

export const ORANGE: ColorPartial = {
  600: '#A73F23',
  500: '#F26741',
  400: '#F37654',
  300: '#F58D70',
  200: '#F7B39F',
  100: '#FBD9D0',
  50: '#FDF0EC',
};

export const GRAY: ColorPartial = {
  600: '#8A95A3',
  500: '#9AA5B5',
  400: '#A3AEBC',
  300: '#B3BBC6',
  200: '#CCD2DA',
  100: '#E6E9ED',
  50: '#F5F6F8',
};

export const GRAY_PALETTE: PaletteColor = {
  main: GRAY[500]!,
  light: GRAY[100]!,
  dark: GRAY[600]!,
  contrastText: 'white',
};

export const GREEN: ColorPartial = {
  600: '#11B95C',
  500: '#13CE66',
  400: '#2CD375',
  300: '#4EDA8C',
  200: '#89E7B3',
  100: '#C4F3D9',
  50: '#E7FAF0',
};

export const RED: ColorPartial = {
  600: '#E5010C',
  500: '#FF020D',
  400: '#FF1925',
  300: '#FF4049',
  200: '#FF8086',
  100: '#FFBFC3',
  50: '#FFE5E7',
};

export const YELLOW: ColorPartial = {
  600: '#CA8E21',
  500: '#FFB01F',
  400: '#FCB93E',
  300: '#FDC55F',
  200: '#FED894',
  100: '#FEEBCA',
  50: '#FFF7EA',
};
