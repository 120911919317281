import styled, { css } from 'styled-components';

import { Paper } from '@mui/material';

export const PolicyContainer = styled(Paper)<{ highlighted?: boolean; recommended?: boolean }>`
  display: flex;
  max-width: ${({ recommended, highlighted }) => (highlighted || recommended ? '328px' : '320px')};
  padding: 0 ${({ theme }) => theme.spacing(2)};
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.spacing(2)};
  align-items: center;
  flex-direction: column;
  box-shadow: none;
  border: 1px solid ${({ theme }) => theme.palette.grey[100]};
  ${({ recommended, theme }) =>
    recommended &&
    css`
      border: 4px solid ${theme.palette.accent.main};
    `}

  ${({ highlighted, theme }) =>
    highlighted &&
    css`
      border: 4px solid ${theme.palette.primary.main};
    `}
  position: relative;
  height: 100%;
  overflow: hidden;
  justify-self: center;
`;

export const CompanyLogo = styled.img`
  margin-top: ${({ theme }) => theme.spacing(1)};
  height: ${({ theme }) => theme.spacing(5)};
`;

export const HighlightLabel = styled.div<{ recommended?: boolean }>`
  background: ${({ theme, recommended }) => {
    if (recommended) {
      return theme.palette.accent.main;
    }
    return theme.palette.grey[100];
  }};
  padding: ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(2)};
  display: flex;
  justify-content: center;
  border-bottom-left-radius: ${({ theme }) => theme.spacing(2)};
  width: inherit;
  max-width: 70%;
  box-sizing: border-box;
  font-weight: bold;
  position: absolute;
  top: 0;
  right: 0;
`;

export const CheapestLabel = styled(HighlightLabel)`
  background: ${({ theme }) => theme.palette.grey[100]};
`;

export const HighlightLabelSmall = styled(CheapestLabel)`
  background: ${({ theme }) => theme.palette.primary.main};
`;
